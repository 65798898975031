.login_screen {
    min-width: unset;
}

.login_main {
    background-image: url('./../../assets/login-bg.jfif');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .login_main_popup {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        max-width: 550px;
        max-height: 440px;
        height: 100%;
        width: 100%;
        border-radius: 15px;
        display: flex;

        .form_title {
            margin-bottom: 10px;

            .form_title {
                margin-bottom: 10px;
            }
        }

        .login_popup_image {
            max-width: 250px;
            width: 100%;
            height: 100%;
            background-image: url('./../../assets/login.png');
            background-color: black;
            background-size: cover;
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
            position: relative;

            &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                content: "";
                left: 0;
                top: 0;
                background: rgba(0, 0, 0, 0.8);
                border-bottom-left-radius: 15px;
                border-top-left-radius: 15px;
            }
        }

        .login_form_main {
            max-width: calc(550px - 250px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-self: center;
            justify-content: center;
            padding: 25px 30px;

            .login_form {
                display: flex;
                flex-direction: column;

                .input_group {
                    margin-bottom: 10px;

                    input {
                        width: 100%;
                        padding: 6px 12px;
                    }
                }

                .validation {
                    display: flex;
                    align-items: top;

                    .validation_code {
                        text-align: center;
                        max-width: 70px;
                        min-width: 70px;
                        min-height: 31px;
                        max-height: 31px;
                        font-size: 16px;
                    }
                }

                button {
                    padding: 6px 20px;
                }
            }
        }
    }

    .login_footer {
        background-color: rgba(0, 0, 0, 0.6);
        max-width: 550px;
        width: 100%;
        margin-top: 20px;

        .login_footer_social {
            display: flex;
            align-items: center;
            justify-content: center;

            .social_icon {
                padding: 10px 12px;

                img {
                    height: 25px;
                    width: 25px;
                }
            }
        }

        .login_footer_contact {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: $white;
                font-size: 14px;
                padding: 10px 12px;
            }
        }
    }

    &.changepass {
        align-items: center;

        .login_main_popup {
            background-color: #ffcc2f;
            height: auto;

            &_left {
                border-right: solid 1px #d99d0c;
                width: 50%;
                padding: 48px;
                padding-left: 15px;
                padding-right: 33px;

                ul {
                    padding-left: 32px;

                    li {
                        list-style: disc;
                        font-size: 12px;
                        color: #000;
                        font-family: 'Tahoma';
                        display: list-item;
                        font-weight: 400;
                        line-height: 1.5;
                    }
                }
            }
        }

        .login_form_main {
            width: 50%;
            padding: 42px;

            .form_title {
                font-size: 20px;
                list-style: 24px;
                font-weight: 500;
                margin-bottom: 16px;
            }

            .input_group {
                input {
                    height: 35px;
                    background: #fff;
                    width: 100%;
                    border-radius: 4px;
                    font-size: 13px;
                    color: #212529;
                    font-weight: 400;
                    padding: 6px 12px;
                    border: 1px solid #ced4da;

                    &::placeholder {
                        font-size: 13px;
                    }
                }
            }

            button {
                padding: 8px 20px !important;
                border: solid 1px transparent;

                &:hover {
                    border-color: #000;
                    background-color: transparent;
                }
            }
        }
    }
}

//////// new css mk
// @include bp(mob) {
.login_main.mobile_screen {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: .4em;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        overflow: visible;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.5);
    }

    &:hover .cover-bar {
        opacity: 0;
        -webkit-transition: all .5s;
    }
}

.login.popup-wrp {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 24;
    width: 100%;
    height: 100vh;
    margin: auto;
}

.login_main_absolute {
    position: absolute;
    top: 0;
    z-index: 9;
}

.login_main.mobile_screen {
    background-size: cover;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #00000050;

    &.login-screen {
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-image: unset !important;
    }

    background-image: none;
    justify-content: unset;

    .login_main_popup {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        max-width: 550px;
        max-height: 440px;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        display: flex;
        box-shadow: none;

        .form_title {
            margin-bottom: 10px;
            text-align: center;
        }

        .login_popup_image {
            max-width: 250px;
            width: 100%;
            height: 100%;
            background-image: url('./../../assets/login.png');
            background-color: black;
            background-size: cover;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            position: relative;

            &:before {
                position: absolute;
                width: 100%;
                height: 100%;
                content: unset;
                left: 0;
                top: 0;
                background: rgba(0, 0, 0, 0.8);
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
            }
        }

        .login_form_main {
            max-width: calc(550px - 250px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-self: center;
            justify-content: center;
            padding: 25px;
            position: relative;
            height: 100%;
            padding-bottom: 0px;

            .close {
                position: absolute;
                right: 10px;
                top: 10px;
                background-position: -394px -1300px;
                height: 20px;
                width: 20px;
                background-image: url("../../../public/images/front-all-icon1.png");

                &.login-close {
                    position: fixed;
                    top: 40px;
                    right: 40px;
                }
            }

            .login_form {
                display: flex;
                flex-direction: column;
                width: 84vw;
                margin: 0 auto;
                color: #fff;

                .input_group {
                    margin-bottom: 10px;
                    margin-bottom: 3.2vw;

                    input {
                        width: 100%;
                        // height: 33px;
                        padding: 8px;
                        font-size: 14px;
                        line-height: 21px;
                        border: 1px solid #aaa;
                        box-shadow: inset 0px 2px 0px 0px #0000001a;
                        margin: 0;
                    }

                    input {
                        border-width: 0;
                        margin-bottom: 0;
                        position: relative;
                        background: #fff;
                        box-shadow: inset 0 0.5333333333vw 0 0 rgba(0, 0, 0, .1);
                        border-radius: 1.6vw;
                        color: #1e1e1e;
                        font-size: 4vw;
                        font-family: Helvetica, Tahoma, sans-serif;
                        padding: 2.6666666667vw 1.8666666667vw;
                        -webkit-appearance: none;
                    }

                    div {
                        span {
                            img {
                                width: 6vw;
                                margin-right: 8px;
                            }
                        }
                    }
                }

                .validation {
                    display: flex;
                    align-items: top;
                    position: relative;
                    width: 100%;

                    .input_group {
                        width: 100%;
                    }

                    .validation_code {
                        position: absolute;
                        right: 15px;
                        margin: auto;
                        top: 6px;
                        text-align: center;
                        max-width: 16vw;
                        min-width: 70px;
                        min-height: 31px;
                        max-height: 31px;
                        font-size: 16px;
                        color: #000 !important;
                        font-family: ubuntu;
                        font-weight: bolder;
                        font-size: 5vw;
                    }
                }

                button {
                    line-height: 36px;
                    font-size: 15px;
                    margin: 15px 0 0;
                    width: 100%;
                    color: #f8d61c;
                    border-radius: 4px;
                    // background: #121212 url("./../../../public/images/bg-send-skyEX.png") repeat-x;
                    background-image: var(--headerBgColor);
                    border: 1px solid #000;
                    appearance: none;
                    cursor: pointer;
                    font-weight: bold;

                    &:hover {
                        background: #464646 url("./../../../public/images/bg-send_hover-skyEX.png") repeat-x;
                    }

                    span {
                        display: none;
                    }

                    margin-top: 0px;
                    border-radius: 1.6vw;
                    font-size: 4vw;
                    font-weight: bold;
                    line-height: 2.6;
                }
            }
        }

        &.login-screen {
            .close {
                position: fixed;
                top: 30px;
                right: 15px;
            }

            max-width: 100%;
            flex-wrap: wrap;
            align-content: center;
            border-radius: 0;
            max-height: unset;
            height: auto;

            .login_popup_image {
                max-width: 100%;
                border-radius: 0;
                height: 74.66667vw;
                background-position: center;
                background-size: 100% 100% !important;
                background-repeat: no-repeat;
                background-color: transparent;

                &::before {
                    border-radius: 0;
                    content: unset;
                }
            }

            .login_form_main {
                margin: 0 auto;
                height: auto;
                max-width: unset;
            }

            .close {
                top: 0;
                background-image: none;
                position: fixed;
                right: 1.8666666667vw;
                justify-content: center;
                align-items: center;
                width: 9.3333333333vw;
                height: 9.3333333333vw;
                background-color: rgba(0, 0, 0, .7);
                border-radius: 50%;
                margin-top: 1.8666666667vw;
                z-index: 2;
                display: flex;
            }

            .close:before {
                content: "";
                width: 3.4666666667vw;
                height: 3.4666666667vw;
                background: url("./../../assets/images/login-close.svg") no-repeat;
                background-size: contain;
                display: block;
            }
        }
    }

    .login_footer {
        padding: 0 20px;
        max-width: 550px;
        width: 100%;
        margin-top: 20px;

        &.mobile_view {
            display: none;
            margin: 0 auto;
        }

        .footer {
            display: block !important;
            margin-top: 0;

            .policy-link {
                border: none;
                margin-top: 0;
                width: 100vw;
                margin: 3.2vw auto 8vw;
                text-align: center;
                padding: 0 1px;
            }
        }

        max-width: 100%;
        margin: 0;
        padding: 0px;

        &.mobile_view {
            display: block;
        }

        .login_footer_social {
            display: flex;
            align-items: center;
            justify-content: center;

            .social_icon {
                padding: 10px 12px;

                img {
                    height: 25px;
                    width: 25px;
                }
            }
        }

        .login_footer_contact {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: $white;
                font-size: 14px;
                padding: 10px 12px;
            }
        }
    }

    &.changepass {
        align-items: center;
        background-image: url('./../../assets/images/change-login.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 15px;

        .login_main_popup {
            background-color: #fff !important;
            height: auto;
            max-width: 480px;
            display: block;
            max-height: 507px;

            &_wrp {
                display: flex;
                min-height: 170px;
                padding: 30px 20px;
                padding-bottom: 40px;
                flex-wrap: wrap;
                justify-content: center;
            }

            &_left {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                width: calc(100% - 230px - 27px);
                font-size: 12px;
                color: #666;
                line-height: 17px;
                margin: 40px 12px 0 15px;
                width: 100%;
                margin-bottom: 15px;
                margin-top: 0;

                ul {
                    li {
                        list-style: disc;
                        font-size: 12px;
                        color: #666;
                        font-family: 'Tahoma';
                        display: list-item;
                        font-weight: 400;
                        line-height: 17px;
                    }
                }
            }
        }

        .login_form_main {
            // width: 50%;
            width: 230px;
            padding: 0;
            width: 100%;

            .form_title {
                font-size: 20px;
                list-style: 24px;
                font-weight: 500;
                margin-bottom: 16px;
            }

            .input_group {
                input {
                    height: 35px;
                    background: #fff;
                    width: 100%;
                    border-radius: 4px;
                    font-size: 13px;
                    color: #212529;
                    font-weight: 400;
                    padding: 6px 12px;
                    border: 1px solid #ced4da;

                    &::placeholder {
                        font-size: 13px;
                    }
                }
            }

            button {
                margin-top: 5px !important;
                padding: 0 !important;
                border: solid 1px transparent;
                border-color: #222 !important;
                height: 36px !important;
                line-height: 36px !important;
                font-size: 15px !important;
                background-image: linear-gradient(180deg, #474747 0%, #070707 100%) !important;

                &:hover {
                    border-color: #000;
                    background-color: transparent;
                }
            }
        }

        .maintain-footer {
            background-image: linear-gradient(180deg, #383838 0%, #010101 100%);
            position: relative;
            width: 100%;
            height: 70px;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;

            img {
                width: 132px;
                margin: 0 auto;
            }
        }
    }

    .login_footer {
        background: none !important;
    }
}

// }
//New Login Page
.velki {
    .bg-cover {
        background-image: url('./../../assets/images/globle-bg.jpeg');
    }

    .flex-center {
        display: flex;
        align-items: center;
    }

    .bg-no-repeat {
        background-repeat: no-repeat !important;
    }

    .bg-cover {
        background-size: cover !important;
    }

    .justify-center {
        justify-content: center !important;
    }

    .h-full {
        height: 100% !important;
    }

    .ml-0 {
        margin-left: 0px !important;
    }

    .rounded-xl {
        border-radius: 0.75rem !important;
    }

    .justify-center {
        justify-content: center !important;
    }

    .items-center {
        align-items: center !important;
    }

    .sm\:flex {
        @media screen and (min-width :640px) {
            display: flex !important;
        }
    }
}

.velki .logo-theme,
.velki .side-logo {
    background-image: url('./../../assets/images/velki-logo.png');
    background-position: 50%;
}

.velki .logo-theme {
    height: 5rem;
    width: 14rem;
}

.velki {
    .logo-theme {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .divide-opacity-30> :not([hidden])~ :not([hidden]) {
        // --tw-divide-opacity: 0.3 !important;
    }

    .divide-white> :not([hidden])~ :not([hidden]) {
        // --tw-divide-opacity: 1 !important;
        border-left: 1px solid #5e5e5e;
    }

    .divide-x-0> :not([hidden])~ :not([hidden]) {
        // --tw-divide-x-reverse: 0 !important;
        // border-right-width: calc(0px* var(--tw-divide-x-reverse)) !important;
        // border-left-width: calc(0px* calc(1 - var(--tw-divide-x-reverse))) !important;
    }

    .w-60 {
        width: 15rem !important;
    }

    .lg-title {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 700;
    }

    .text-white {
        --tw-text-opacity: 1 !important;
        // color: rgba(255, 255, 255, 1 !important) !important;
    }

    .text-center {
        text-align: center !important;
    }

    .lg-title {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 700;
    }

    .text-white {
        --tw-text-opacity: 1 !important;
        color: #FFFFFF !important;
    }

    .text-3xl {
        font-size: 1.875rem !important;
        line-height: 2.25rem !important;
    }

    .mr-2 {
        margin-right: 0.5rem !important;
    }

    .space-y-3> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        // margin-top: calc(0.75rem* calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom: calc(0.75rem* var(--tw-space-y-reverse)) !important;
    }

    .leading-10 {
        line-height: 2.5rem !important;
    }

    .h-10 {
        height: 2.5rem !important;
    }

    input,
    select,
    textarea,
    [type="text"],
    [type="password"] {
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    input,
    select,
    textarea,
    [type="text"],
    [type="password"] {
        padding: 0.25rem;
        font-size: 0.75rem;
        line-height: 1rem;
        --tw-text-opacity: 1;
        color: rgba(75, 85, 99, var(--tw-text-opacity));
    }

    input,
    select,
    textarea,
    [type="text"],
    [type="password"] {
        --tw-bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    }

    input,
    select,
    textarea,
    [type="text"],
    [type="password"] {
        display: block;
        height: 1.75rem;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.125rem;
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgba(209, 213, 219, var(--tw-border-opacity));
    }

    .sm\:pl-7 {
        padding-left: 1.75rem !important;
    }

    .logo-theme {
        margin-right: 1.75rem;
        margin-bottom: 1.25rem;
    }

    .space-y-3> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        margin-top: calc(0.75rem* calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0.75rem* var(--tw-space-y-reverse)) !important;
    }

    .leading-10 {
        line-height: 2.5rem !important;
    }

    .h-10 {
        height: 2.5rem !important;
    }

    .space-y-3> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        // margin-top: calc(0.75rem* calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom: calc(0.75rem* var(--tw-space-y-reverse)) !important;
    }

    .text-red-600 {
        margin-top: 0px !important;
        text-align: left !important;
    }

    .relative {
        position: relative !important;
    }

    .w-1\/3 {
        width: 33.333333% !important;
    }

    .right-1 {
        right: 0.25rem !important;
    }

    .top-3 {
        font-size: 24px;
        top: 5px !important;
    }

    .absolute {
        position: absolute !important;
    }

    .space-y-3> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 0 !important;
        // margin-top: calc(0.75rem* calc(1 - var(--tw-space-y-reverse))) !important;
        margin-bottom: calc(0.75rem* var(--tw-space-y-reverse)) !important;
    }

    .btn-highlight {
        --tw-text-opacity: 1;
        color: rgba(0, 0, 0, 1) !important;
    }

    .btn-highlight {
        border-width: 1px;
        --tw-border-opacity: 1;
        border-color: rgba(245, 158, 11, var(--tw-border-opacity));
        --tw-border-opacity: 0.7;
        background-color: #ffcc2f !important;
    }

    .btn {
        display: inline-flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 0.125rem;
        --tw-bg-opacity: 1;
        background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .text-black {
        --tw-text-opacity: 1 !important;
        color: rgba(0, 0, 0, 1) !important;
    }

    .leading-10 {
        line-height: 2.5rem !important;
    }

    .px-3 {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }

    .rounded-sm {
        border-radius: 0.125rem !important;
    }

    .w-full {
        width: 100% !important;
    }

    .h-10 {
        height: 2.5rem !important;
    }

    .text-base {
        font-size: 1rem !important;
        line-height: 1.5rem !important;
        font-weight: 400;
    }

    .loginBtn svg {
        height: 1.25rem;
        width: 1.25rem;
        margin-left: 10px;
    }

    .w-60 {
        &.space-y-3 {
            @media screen and (max-width :640px) {
                border: 0 !important;
                padding-left: 0 !important;
            }
        }
    }
}

.login_main .form-size {
    transform: scale(2.5) !important;
    flex-direction: column !important;

}

.login_main.velki .divide-white> :not([hidden])~ :not([hidden]) {
    border-left: 0 !important;
    padding-left: 0 !important;
}

.login_main.velki .logo-theme {
    margin-right: 0;
}



.social_tab {
    margin-top: 180px !important;
    transform: scale(2.5);
    .row {
        margin: 0 -12px;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 992px) {
            margin: 0;
        }
        .nav {
            &.nav-pills {
                display: flex;
                flex-wrap: wrap;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;
                position: relative;
                width: 100%;
                justify-content: center;
                border: 0.26667vw solid rgba(255, 255, 255, 0.3);
                &::before {
                    content: "";
                    display: block;
                    flex: 1 1;
                    align-self: flex-end;
                    height: 1px;
                    margin-bottom: -1px;
                    background-color: #00000026;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                }
                .nav-item {
                    position: relative;
                    .nav-link {
                        position: relative;
                        padding: 10px 12px;
                        background: 0 0;
                        border: 0;
                        border-radius: 0.25rem;
                        display: block;
                        opacity: 0.5;
                        img {
                            width: 25px;
                            display: block;
                        }
                        &:hover,
                        &.open {
                            opacity: 1;
                            .nav-link {
                                opacity: 1;
                            }
                            &::before {
                                content: "";
                                position: absolute;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                top: 100%;
                                left: 50%;
                                margin-left: -6px;
                                border-top: 6px solid #ccc9bf;
                                margin-top: 1px;
                                z-index: 1;
                            }
                            &::after {
                                content: "";
                                border-top: 6px solid #f0ece1;
                                margin-top: 0;
                                z-index: 1;
                                position: absolute;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                top: 100%;
                                left: 50%;
                                z-index: 1;
                                margin-left: -6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.support-wrap {
    // background-color: #ffffff80;
    background-color: rgb(0, 0, 0, 0.6);
    color: #0009;
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 20px;
    .support-info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        .support-detail {
            display: none;
            &.open {
                display: block;
            }
            a {
                font-size: 14px;
                margin-right: 8px;
                color: #ffffff;
                text-decoration: none;
                &:nth-child(2n) {
                    padding: 0 8px;
                    border-left: 1px solid #0003;
                    border-right: 1px solid #0003;
                }
                &:last-child {
                    margin-right: 0;
                    padding-right: 0;
                    border-right: 0;
                }
            }
        }
    }
}

.login-form {
    background: #000000;
    height: 100vh;
    overflow: hidden;

    .login-img {
        text-align: center;
        max-width: 660px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-size: 100% 100% !important;
        border-radius: 0;
        height: 50vh;
        max-width: 100%;

        img {
            margin: 0 auto;
        }
    }

    .form-info {
        max-width: 230px;
        margin: 190px auto 0;
        transform: scale(2.2);

        .logo-img {
            img {
                height: 45px;
                margin: 0 auto;
            }
        }

        h5 {
            text-align: center;
            font-size: 14px;
            line-height: 28px;
            color: #fff;
            margin-bottom: 10px;
        }

        form {
            text-align: left;
            transform: scale(1.5);

            .form-group {

                margin-bottom: 10px;

                label {
                    display: block;
                    font-size: 12px;
                    color: #ffffff;
                    margin-bottom: 8px;
                }
                .icon-input{
                    position: relative;
                    img{
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 15px;
                        height: 15px;
                    }
                }
                input:-webkit-autofill{
                    // -webkit-text-fill-color: #000 !important;
                    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
                }
                input {
                    background-color: transparent;
                    border: 1px solid #ffffff;
                    border-radius: 20px;
                    width: 100%;
                    color: #ffffff;
                    padding: 6px 6px 6px 30px;

                    &::placeholder {
                        color: #ffffff;
                    }
                }

                .loginBtn {
                    color: #000;
                    background: #e1ad43;
                    font-size: 12px;
                    padding: 2px;
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;

                    svg {
                        width: 12px;
                    }
                }
            }

        }
    }
}